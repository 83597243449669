import api from '../baseAPIs/shopify';
import { DTO } from '@extTypes/dto/productDto';
import { Product, ProductCreateImage, ProductImage } from '@extTypes/shopify/product';
import { StoreProductCreateDto, StoreProductCreateResponse } from '@extTypes/api/products';
import axios from 'axios';

export const getProducts = async (
    searchString: string,
    from: number,
    limit: number,
    includeVariants: boolean,
    linkedProducts: string,
    store: string
): Promise<DTO.ProductsListingApiResponse> => {
    const response = await api.get('products', {
        params: {
            searchString: searchString,
            from: from,
            limit: limit,
            includeVariants: includeVariants,
            linkedProducts: linkedProducts,
            shop: store
        }
    });
    return response.data;
};

export const getProductByShopifyId = async (shopifyId: number, store: string): Promise<DTO.Product.Product> => {
    const response = await api.get(`/products/${shopifyId}`, {
        params: {
            shop: store
        }
    });
    return response.data;
};

export const getStoreProductById = async (storeProductId: string, store: string): Promise<Product> => {
    return api
        .get(`/storeProducts/${storeProductId}`, {
            params: { shop: store }
        })
        .then(({ data }) => data);
};

export const saveProduct = async (productId: string, product: Partial<DTO.Product.Product>, store: string) => {
    product.id = Number(productId).toString();
    const response = await api.put('products', product, {
        params: {
            shop: store
        }
    });
    return response.data;
};

export const startProductSync = async (store: string) => {
    const response = await api.put(
        'products/status',
        {
            status: 'sync'
        },
        {
            params: {
                shop: store
            }
        }
    );
    return response.data;
};

export const getProductsStatus = async (
    store: string
): Promise<{
    synchronization: 'error' | 'completed' | 'synchronizing' | 'unknown';
}> => {
    const response = await api.get('products/status', {
        params: {
            shop: store
        }
    });
    return response.data;
};

export const getProduct = async (id: string, store: string): Promise<DTO.Product.Product> => {
    const response = await api.get('products/' + id, {
        params: {
            shop: store
        }
    });
    return response.data;
};

export const getProductUrl = async (id: string, store: string): Promise<{ url: string }> => {
    const response = await api.get(`products/${id}/url`, {
        params: {
            shop: store
        }
    });
    return response.data;
};

export const getProductAmazonUrl = async (id: string, store: string): Promise<{ url: string }> => {
    const response = await api.get(`amazon/product/${id}/url`, {
        params: {
            shop: store
        }
    });
    return response.data;
};

export const startImportProductsConfig = async (store: string) => {
    const response = await api.put(
        'products/config/status',
        {
            status: 'importing'
        },
        {
            params: {
                shop: store
            }
        }
    );
    return response.data;
};

export const getProductsConfigImportStatus = async (
    store: string
): Promise<{
    import: 'error' | 'completed' | 'importing' | 'unknown' | 'not-imported';
}> => {
    const response = await api.get('products/config/status', {
        params: {
            shop: store
        }
    });
    return response.data;
};

export const createStoreProduct = async (
    store: string,
    setsIds: string[],
    initialTemplateId: string,
    product: StoreProductCreateDto
): Promise<StoreProductCreateResponse> => {
    try {
        const response = await api.post(
            'products/store',
            {
                setsIds,
                initialTemplateId: initialTemplateId,
                product: product
            },
            {
                params: {
                    shop: store
                }
            }
        );
        return { product: response.data };
    } catch (error) {
        const msg = axios.isAxiosError(error) ? error.response?.data?.error : (error as string);
        return { error: `Could not create the product on the store: ${msg || (error as string)}` };
    }
};

export const updateProductConfiguration = async (
    storeProductId: string,
    store: string,
    provider: string,
    productConfig: DTO.Product.Configuration
): Promise<Product> => {
    return api
        .put<Product>(`products/config/${storeProductId}`, productConfig, { params: { shop: store, provider } })
        .then(({ data }) => data);
};

export const updateProductInStore = async (
    store: string,
    id: string,
    product: Partial<StoreProductCreateDto>
): Promise<Product> => {
    const response = await api.put(`/products/store/${id}`, product, {
        params: {
            shop: store
        }
    });

    return response.data;
};

export const createProductImage = async (
    store: string,
    productId: string,
    productImage: ProductCreateImage
): Promise<ProductImage> => {
    const response = await api.post(`/products/images/${productId}`, productImage, {
        params: {
            shop: store
        }
    });

    return response.data;
};
